<template>
  <vuestic-widget :loading="isLoadingForm">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Update Form</span>
        <router-link
          class="btn btn-primary btn-sm"
          :to="{ name: 'business.embedded-forms.index', params: { id: $route.params.install } }"
        >
          <span>Back To List <i class="fa fa-arrow-left"></i></span>
        </router-link>
      </div>
    </template>
    
    <!-- language selection start -->
    <div class="row select-div mb-4">
      <div class="col-md-6 text-left" style="max-width: 500px">
        <div class="d-flex align-items-end justify-content-center">
          <div class="d-block" style="width: 300px">
            <label class="field-label">Language</label>
            <multiselect v-model="selectedMainLanguage" :options="mainLanguageList" :multiple="false"
              :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="" label="label"
              track-by="label" :preselect-first="true" :show-labels="false" :allowEmpty="false" @input="onSelectLanguage">
            </multiselect>
          </div>
          <button v-if="selectedMainLanguage.id != 'en'" class="btn btn-danger ml-2" type="button" @click="removeLanguage">
            <span><i class="fa fa-trash"></i></span>
          </button>
          <button class="btn btn-primary ml-2" type="button" @click="openModalLanguage">
            <span><i class="fa fa-plus"></i> Add</span>
          </button>
        </div>
      </div>
    </div>

    <WizardForm v-if="form"  :mainLanguageIndex="mainLanguageIndex" :form="form" @form-changed="onFormChanged"></WizardForm>

    <vuestic-modal :isOpen="isOpenModalLanguage" @cancel="closeModalLanguage" :cancelShown="false" :okShown="false">
      <span slot="title">Add Language</span>
      <div class="row">
        <div class="col-md-12">
          <h6 class="mb-3">You can select language here</h6>
          <label class="field-label">Language</label>
          <multiselect class="filter-select d-inline-block" v-model="selectedLanguage" :options="allLanguagesList"
            :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Select Language"
            label="label" track-by="id" :preselect-first="true" :show-labels="false" :searchable="true" :allowEmpty="false">
          </multiselect>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-12 d-flex justify-content-center">
          <button @click="addInstallLanguage" class="btn btn-primary" style="min-width: 80px;">
            <span>Add</span>
          </button>
          <button type="button" @click="closeModalLanguage" class="btn btn-outline ml-2">Close</button>
        </div>
      </div>
    </vuestic-modal>


  </vuestic-widget>
</template>

<script>
import WizardForm from './WizardForm'
import { languageList } from "../../../constants/language";
const merge = require('deepmerge')

export default {
  components: {
    WizardForm,
  },

  data () {
    return {
      form: null,
      isLoadingForm: true,
       isOpenModalLanguage: false,
      allLanguagesList: languageList,
      selectedLanguage: "",
      mainLanguageList: [],
      mainLanguageIndex: 0,
      selectedMainLanguage : {"id": "en", "label": "English"},
    }
  },

  mounted () {
    this.loadForm()
  },

  methods: {
    loadForm () {
      this.isLoadingForm = true

      this.$store.dispatch('install/findForm', {
        install: this.$route.params.install,
        id: this.$route.params.id
      })
        .then((form) => {
          let formOriginal = JSON.parse(JSON.stringify(this.$store.getters['install/originalForm']))
          this.form = merge.all([{},
            formOriginal,
            form,
          ])
           this.selectedMainLanguage = languageList.find(language => language.id == 'en');
          this.mainLanguageList = this.form.sections.languageList.map(row => languageList.find(language => language.id === row.language));
          this.mainLanguageIndex = this.form.sections.languageList.findIndex(obj => obj.language === this.selectedMainLanguage.id)
          this.isLoadingForm = false
        })
        .catch(() => {
          this.$router.push({ name: 'business.embedded-forms.index', params: { install: this.$route.params.install } })
        })
    },

    onFormChanged (form) {
      this.form = form
    },

    removeLanguage () {
      let selectedKey = this.selectedMainLanguage.id;
      this.selectedMainLanguage = {"id": "en", "label": "English"};
      this.mainLanguageIndex = this.form.sections.languageList.findIndex(obj => obj.language == this.selectedMainLanguage.id)
      let removeIndexMainLanguage = this.mainLanguageList.findIndex(obj => obj.id == selectedKey)
      this.mainLanguageList.splice(removeIndexMainLanguage, 1);
      let removeIndexInstallProperty = this.form.sections.languageList.findIndex(obj => obj.language == selectedKey)
      this.form.sections.languageList.splice(removeIndexInstallProperty, 1);
      this.showToast("Selected language is removed form the list");
    },
    onSelectLanguage (value) {
      this.mainLanguageIndex = this.form.sections.languageList.findIndex(obj => obj.language == value.id)
    },
    addInstallLanguage (){
      let findIndex = this.form.sections.languageList.findIndex(obj => obj.language == this.selectedLanguage.id)
      if(findIndex == -1) {
        let englishLanguageIndex = this.form.sections.languageList.findIndex(obj => obj.language === 'en')
        let newItem = {'language' : this.selectedLanguage.id, 'value' : JSON.parse( JSON.stringify(this.form.sections.languageList[englishLanguageIndex].value))};
        this.form.sections.languageList.push(newItem);
        this.mainLanguageList.push(this.selectedLanguage) 
      }
      this.isOpenModalLanguage = false
    },
    openModalLanguage () {
      this.isOpenModalLanguage = true
    },
    closeModalLanguage () {
      this.isOpenModalLanguage = false
    },


  },
};
</script>

<style scoped lang="scss">
  .select-div {
    justify-content: center;
  }
</style>